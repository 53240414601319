import { generateUniqueId } from "../libs/tools.js";
import { getEncryptedError } from "../libs/error.js";

const state = {
  notifications: [],
  notificationCenterIsVisible: false,
};

const actions = {
  add(ctx, data) {
    ctx.commit("add", data);
    // ctx.dispatch("showNotificationCenter");
  },
  remove(ctx, id) {
    ctx.commit("remove", id);
  },
  showNotificationCenter(ctx) {
    ctx.commit("updateNotificationCenterVisibility", true);
  },
  hideNotificationCenter(ctx) {
    ctx.commit("updateNotificationCenterVisibility", false);
    ctx.dispatch("allReaded");
  },
  expandNotification(ctx, { id, expand }) {
    ctx.commit("expandNotification", { id: id, expand: expand });
  },
  readed(ctx, id) {
    ctx.commit("readed", id);
  },
  allReaded(ctx) {
    ctx.commit("allReaded");
  },
  hideInToaster({ commit }, index) {
    commit("displayInToaster", { index: index, value: false });
  },
};

const mutations = {
  add(state, { type, message, status, stack, time }) {
    const notification = {
      id: generateUniqueId(),
      type: type,
      message: message,
      status: status || null,
      stack: stack || null,
      time: time,
      expand: false,
      startRemoveAnimation: false,
      isNotReaded: true,
      displayInToaster: true,
    };

    notification.encryptedError = getEncryptedError(notification);

    state.notifications.push(notification);
  },
  remove(state, id) {
    const notificationIndex = state.notifications
      .map((item) => item.id)
      .indexOf(id);

    state.notifications[notificationIndex].startRemoveAnimation = true;

    setTimeout(() => {
      state.notifications.splice(notificationIndex, 1);
    }, 300);
  },
  updateNotificationCenterVisibility(state, visibility) {
    state.notificationCenterIsVisible = visibility;
  },
  expandNotification(state, { id, expand }) {
    let notification = state.notifications.find((e) => e.id == id);

    if (notification) notification.expand = expand;
  },
  readed(state, id) {
    let notification = state.notifications.find((e) => e.id == id);

    if (notification) notification.isNotReaded = false;
  },
  allReaded(state) {
    for (let notification of state.notifications) {
      if (notification.isNotReaded) notification.isNotReaded = false;
    }
  },
  displayInToaster(state, { index, value }) {
    state.notifications[index].displayInToaster = value;
  },
};

const getters = {
  numberOfUnread(state) {
    return state.notifications.filter((el) => {
      return el.isNotReaded;
    }).length;
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};

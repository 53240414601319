import { useCookies } from "vue3-cookies";
import { removeAuthorizationHeader, setAuthorizationHeader } from "./api";

export const setAuthorization = (token, expiresIn) => {
  const { cookies } = useCookies();

  cookies.set("authorization", token, expiresIn);

  setAuthorizationHeader();
};

export const removeAuthorization = () => {
  const { cookies } = useCookies();

  cookies.remove("authorization");

  removeAuthorizationHeader();
};

export const getAuthorizationToken = () => {
  const { cookies } = useCookies();

  return cookies.get("authorization");
};

import apiService from "../services/api.service";

const getDefaultState = () => {
  return {
    designFields: [],
    designStructure: [],
    originalDesignStructure: [],
    designCenterIsVisible: false,
    currentRouteName: null,
  };
};

const state = getDefaultState();

const actions = {
  async getDesignData(ctx, screenName) {
    const retValue = await apiService.get(`design/${screenName}`);

    if (!retValue || !retValue.data.success) return false;

    const serverData = retValue.data.data;

    if (serverData.fields) ctx.commit("setDesignFields", serverData.fields);

    if (serverData.structure) {
      ctx.commit("setDesignStructure", JSON.parse(serverData.structure));
      ctx.commit(
        "setOriginalDesignStructure",
        JSON.parse(serverData.structure)
      );
    } else {
      ctx.dispatch("generateDefaultStructure");
    }
  },
  async saveDesign({ state, dispatch, commit }) {
    const retValue = await apiService.post(`design/${state.currentRouteName}`, {
      structure: JSON.stringify(state.designStructure),
    });

    if (retValue) {
      commit(
        "setOriginalDesignStructure",
        JSON.parse(JSON.stringify(state.designStructure))
      );
      dispatch("hideDesignCenter");
    }

    return retValue;
  },
  async toggleDesignCenter({ state, dispatch, getters }) {
    if (state.designCenterIsVisible) {
      if (getters.designStructureHasChanged) {
        const dialogValue = await dispatch(
          "dialog/showUnsavedDesignDataDialog",
          null,
          { root: true }
        );

        if (dialogValue === true) {
          dispatch("hideDesignCenter");
        }
      } else {
        dispatch("hideDesignCenter");
      }
    } else dispatch("showDesignCenter");
  },
  showDesignCenter(ctx) {
    ctx.commit("updateDesignCenterVisibility", true);
  },
  hideDesignCenter(ctx) {
    ctx.commit("updateDesignCenterVisibility", false);
  },
  addRow(ctx, { rowIndex }) {
    ctx.commit("addRow", { rowIndex: rowIndex });
  },
  addBlock(ctx, { rowIndex, blockIndex }) {
    ctx.commit("addBlock", { rowIndex: rowIndex, blockIndex: blockIndex });
  },
  addBlockTitle(ctx, { rowIndex, blockIndex, title }) {
    ctx.commit("addBlockTitle", {
      rowIndex: rowIndex,
      blockIndex: blockIndex,
      title: title,
    });
  },
  removeBlockTitle(ctx, { rowIndex, blockIndex }) {
    ctx.commit("removeBlockTitle", {
      rowIndex: rowIndex,
      blockIndex: blockIndex,
    });
  },
  addFieldRow(ctx, { rowIndex, blockIndex, fieldRowIndex }) {
    ctx.commit("addFieldRow", {
      rowIndex: rowIndex,
      blockIndex: blockIndex,
      fieldRowIndex: fieldRowIndex,
    });
  },
  addFieldContainer(
    ctx,
    { rowIndex, blockIndex, fieldRowIndex, fieldContainerIndex }
  ) {
    ctx.commit("addFieldContainer", {
      rowIndex: rowIndex,
      blockIndex: blockIndex,
      fieldRowIndex: fieldRowIndex,
      fieldContainerIndex: fieldContainerIndex,
    });
  },
  async addFieldById(
    ctx,
    { rowIndex, blockIndex, fieldRowIndex, fieldContainerIndex, fieldId }
  ) {
    ctx.commit("addField", {
      rowIndex: rowIndex,
      blockIndex: blockIndex,
      fieldRowIndex: fieldRowIndex,
      fieldContainerIndex: fieldContainerIndex,
      fieldId: fieldId,
    });
  },
  getFieldInfoFromDrop(ctx, fieldId) {
    return ctx.getters.getFieldById(fieldId);
  },
  refreshActiveFields({ state, commit }) {
    let activeIds = [];

    for (const rows of state.designStructure) {
      for (const blocks of rows.childs) {
        for (const fieldRows of blocks.childs) {
          for (const fieldContainer of fieldRows.childs) {
            if (fieldContainer.child) {
              if (
                !state.designFields.find((el) => {
                  return el.id == fieldContainer.child;
                })
              ) {
                // remove outdated design field
                commit("removeDesignStructureChild", fieldContainer.child);
              } else {
                activeIds.push(fieldContainer.child);
              }
            }
          }
        }
      }
    }

    for (const field of state.designFields) {
      if (activeIds.includes(field.id) && !field.active) {
        commit("setFieldActiveById", field.id);
      } else if (!activeIds.includes(field.id) && field.active) {
        commit("setFieldInactiveById", field.id);
      }
    }
  },
  removeField(
    { state, commit, dispatch },
    { rowIndex, blockIndex, fieldRowIndex, fieldContainerIndex }
  ) {
    if (
      state.designStructure[rowIndex].childs[blockIndex].childs[fieldRowIndex]
        .childs[fieldContainerIndex].child !== null
    ) {
      commit("removeField", {
        rowIndex: rowIndex,
        blockIndex: blockIndex,
        fieldRowIndex: fieldRowIndex,
        fieldContainerIndex: fieldContainerIndex,
      });
    } else {
      dispatch("removeFieldContainer", {
        rowIndex: rowIndex,
        blockIndex: blockIndex,
        fieldRowIndex: fieldRowIndex,
        fieldContainerIndex: fieldContainerIndex,
      });
    }
  },
  removeFieldContainer(
    { state, commit },
    { rowIndex, blockIndex, fieldRowIndex, fieldContainerIndex }
  ) {
    if (
      state.designStructure[rowIndex].childs[blockIndex].childs[fieldRowIndex]
        .childs.length <= 1
    ) {
      if (
        state.designStructure[rowIndex].childs[blockIndex].childs.length <= 1
      ) {
        if (state.designStructure[rowIndex].childs.length <= 1) {
          commit("deleteRow", {
            rowIndex: rowIndex,
          });
        } else {
          commit("deleteBlock", {
            rowIndex: rowIndex,
            blockIndex: blockIndex,
          });
        }
      } else {
        commit("deleteFieldRow", {
          rowIndex: rowIndex,
          blockIndex: blockIndex,
          fieldRowIndex: fieldRowIndex,
        });
      }
    } else {
      commit("deleteFieldContainer", {
        rowIndex: rowIndex,
        blockIndex: blockIndex,
        fieldRowIndex: fieldRowIndex,
        fieldContainerIndex: fieldContainerIndex,
      });
    }
  },
  resetOriginalDesignStructure({ commit, state }) {
    commit(
      "setDesignStructure",
      JSON.parse(JSON.stringify(state.originalDesignStructure))
    );
  },
  generateDefaultStructure({ state, dispatch, commit }) {
    let designField,
      previousType = null,
      rowIndex = -1,
      blockIndex = 0,
      fieldRowIndex = 0,
      fieldContainerIndex = 0;

    state.designStructure = [];

    for (var i in state.designFields) {
      designField = state.designFields[i];

      if (designField.type === "STRING" || designField.type === "LIST" || designField.type === "PASSWORD" || designField.type === "PASSWORDCONFIRMATION" || designField.type === "BOOLEAN") {
        if (
          (previousType !== "STRING" && previousType !== "LIST" && previousType !== "PASSWORD" && previousType !== "PASSWORDCONFIRMATION" && previousType !== "BOOLEAN" ) ||
          rowIndex === -1
        ) {
          blockIndex = 0;
          fieldRowIndex = 0;
          fieldContainerIndex = 0;
          rowIndex += 1;

          dispatch("addRow", { rowIndex });
          commit("addField", {
            rowIndex,
            blockIndex,
            fieldRowIndex,
            fieldContainerIndex,
            fieldId: designField.id,
          });
          fieldRowIndex += 1;
        } else {
          dispatch("addFieldRow", {
            rowIndex,
            blockIndex,
            fieldRowIndex,
            fieldContainerIndex,
          });
          commit("addField", {
            rowIndex,
            blockIndex,
            fieldRowIndex,
            fieldContainerIndex,
            fieldId: designField.id,
          });
          fieldRowIndex += 1;
        }
      }

      if (
        designField.type === "TABLE" ||
        designField.type === "SUBTABLE" ||
        designField.type === "SUBTABLE-ADD"
      ) {
        blockIndex = 0;
        fieldRowIndex = 0;
        fieldContainerIndex = 0;
        rowIndex += 1;

        dispatch("addRow", { rowIndex });
        commit("addField", {
          rowIndex,
          blockIndex,
          fieldRowIndex,
          fieldContainerIndex,
          fieldId: designField.id,
        });
      }

      previousType = designField.type;
    }

    commit(
      "setOriginalDesignStructure",
      JSON.parse(JSON.stringify(state.designStructure))
    );
  },
};

const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },
  setDesignFields(state, fields) {
    state.designFields = fields;
  },
  setDesignStructure(state, structure) {
    state.designStructure = structure;
  },
  setOriginalDesignStructure(state, structure) {
    state.originalDesignStructure = structure;
  },
  updateDesignCenterVisibility(state, visibility) {
    state.designCenterIsVisible = visibility;
  },
  addRow(state, { rowIndex }) {
    state.designStructure.splice(rowIndex, 0, {
      type: "row",
      childs: [
        {
          type: "block",
          childs: [
            {
              type: "fieldRow",
              childs: [
                {
                  type: "fieldContainer",
                  child: null,
                },
              ],
            },
          ],
        },
      ],
    });
  },
  deleteRow(state, { rowIndex }) {
    state.designStructure.splice(rowIndex, 1);
  },
  addBlock(state, { rowIndex, blockIndex }) {
    state.designStructure[rowIndex].childs.splice(blockIndex, 0, {
      type: "block",
      childs: [
        {
          type: "fieldRow",
          childs: [
            {
              type: "fieldContainer",
              child: null,
            },
          ],
        },
      ],
    });
  },
  addBlockTitle(state, { rowIndex, blockIndex, title }) {
    state.designStructure[rowIndex].childs[blockIndex].title = title;
  },
  removeBlockTitle(state, { rowIndex, blockIndex }) {
    delete state.designStructure[rowIndex].childs[blockIndex].title;
  },
  deleteBlock(state, { rowIndex, blockIndex }) {
    state.designStructure[rowIndex].childs.splice(blockIndex, 1);
  },
  addFieldRow(state, { rowIndex, blockIndex, fieldRowIndex }) {
    state.designStructure[rowIndex].childs[blockIndex].childs.splice(
      fieldRowIndex,
      0,
      {
        type: "fieldRow",
        childs: [
          {
            type: "fieldContainer",
            child: null,
          },
        ],
      }
    );
  },
  deleteFieldRow(state, { rowIndex, blockIndex, fieldRowIndex }) {
    state.designStructure[rowIndex].childs[blockIndex].childs.splice(
      fieldRowIndex,
      1
    );
  },
  addFieldContainer(
    state,
    { rowIndex, blockIndex, fieldRowIndex, fieldContainerIndex }
  ) {
    state.designStructure[rowIndex].childs[blockIndex].childs[
      fieldRowIndex
    ].childs.splice(fieldContainerIndex, 0, {
      type: "fieldContainer",
      child: null,
    });
  },
  deleteFieldContainer(
    state,
    { rowIndex, blockIndex, fieldRowIndex, fieldContainerIndex }
  ) {
    state.designStructure[rowIndex].childs[blockIndex].childs[
      fieldRowIndex
    ].childs.splice(fieldContainerIndex, 1);
  },
  addField(
    state,
    { rowIndex, blockIndex, fieldRowIndex, fieldContainerIndex, fieldId }
  ) {
    state.designStructure[rowIndex].childs[blockIndex].childs[
      fieldRowIndex
    ].childs[fieldContainerIndex].child = fieldId;
  },
  removeField(
    state,
    { rowIndex, blockIndex, fieldRowIndex, fieldContainerIndex }
  ) {
    state.designStructure[rowIndex].childs[blockIndex].childs[
      fieldRowIndex
    ].childs[fieldContainerIndex].child = null;
  },

  setFieldActiveById(state, fieldId) {
    let fieldIndex = state.designFields.findIndex((el) => el.id == fieldId);

    state.designFields[fieldIndex].active = true;
  },
  setFieldInactiveById(state, fieldId) {
    let fieldIndex = state.designFields.findIndex((el) => el.id == fieldId);

    state.designFields[fieldIndex].active = false;
  },
  SET_ROUTE(state, routeName) {
    state.currentRouteName = routeName;
  },
  removeDesignStructureChild(state, childId) {
    for (const rows of state.designStructure) {
      for (const blocks of rows.childs) {
        for (const fieldRows of blocks.childs) {
          for (const fieldContainer of fieldRows.childs) {
            if (fieldContainer.child && fieldContainer.child.id) {
              if (fieldContainer.child == childId) {
                fieldContainer.child = null;
              }
            }
          }
        }
      }
    }
  },
};

const getters = {
  getFieldById: (state) => (id) => {
    return state.designFields.find((el) => el.id == id);
  },
  getFieldsForQueryParameter: (state) => {
    let names = [];

    for (var i in state.designFields) {
      if (
        state.designFields[i].active &&
        state.designFields[i].type != "TABLE" &&
        state.designFields[i].type != "SUBTABLE" &&
        state.designFields[i].type != "SUBTABLE-ADD"
      ) {
        names.push(
          state.designFields[i].dataIndex.linkIndexes
            ? state.designFields[i].dataIndex.linkIndexes.join("/") +
                "." +
                state.designFields[i].dataIndex.fieldName
            : state.designFields[i].dataIndex.fieldName
        );
      }
    }

    return names.join(",");
  },
  getEmptyFieldsForApi: (state) => {
    let names = {};

    for (var i in state.designFields) {
      if (state.designFields[i].active) {
        names[state.designFields[i].name] = "";
      }
    }

    return names;
  },
  designStructureHasChanged: (state) => {
    return (
      JSON.stringify(state.designStructure) !==
      JSON.stringify(state.originalDesignStructure)
    );
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};

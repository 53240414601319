import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookies from "vue3-cookies";

import Default from "./layouts/Default";
import Empty from "./layouts/Empty";

const app = createApp(App);

app.component("default-layout", Default);
app.component("empty-layout", Empty);

app.config.errorHandler = (err) => {
  console.error(err);
  alert(err);
};

app.use(VueCookies).use(store).use(router).mount("#app");

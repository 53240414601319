<template>
  <!-- <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="'chart-id'"
    class="chart"
  /> -->
  <div class="welcome">Bienvenue sur la page d'accueil</div>
</template>

<script>
// import { Bar } from "vue-chartjs";
// import {
//   Chart as ChartJS,
//   Title,
//   Tooltip,
//   Legend,
//   BarElement,
//   CategoryScale,
//   LinearScale,
// } from "chart.js";

// ChartJS.register(
//   Title,
//   Tooltip,
//   Legend,
//   BarElement,
//   CategoryScale,
//   LinearScale
// );

export default {
  name: "Home",
  components: {
    // Bar,
  },
  data() {
    return {
      // chartData: {
      //   labels: ["January", "February", "March"],
      //   datasets: [{ data: [40, 20, 12] }],
      // },
      // chartOptions: {
      //   responsive: true,
      // },
    };
  },
};
</script>

<style lang="less" scoped>
// .chart {
//   width: 400px;
//   height: 400px
// }

.welcome {
  background: @white;
  padding: 24px;
  border-radius: 12px;
  .body-medium-font();
}

</style>
<template>
  <div
    id="nav"
    v-if="isAuthenticated"
    :class="{
      sidenavIsVisible,
      sidenavOpen,
    }"
    @mouseover="openSidenav"
  >
    <div class="nav-container" @mouseover="openSidenav">
      <router-link
        class="link"
        to="/"
        @mouseover="clearSubNavItems"
        @click="clearSubNavItemsAndClose"
      >
        <div class="line">
          <GoogleSymbol symbol="home" size="24px" :hover="false" class="icon" />
          <span class="label">Accueil</span>
        </div>
      </router-link>

      <!-- Dynamic Menu -->

      <div
        v-for="(menu, index) in menuUtilisateur"
        :key="index"
        class="link"
        :class="{ active: activeIndex == index }"
        @mouseover="setActiveAndSubNavItems(menu.childs, index)"
        @click="setActiveAndSubNavItems(menu.childs, index)"
      >
        <div class="line">
          <GoogleSymbol :symbol="menu.icone" size="24px" :hover="false" class="icon" />
          <span class="label">{{ menu.libelle }}</span>
        </div>
      </div>

      <!-- End Dynamic Menu -->
    </div>

    <div class="subnav-container" v-if="subNavItems.length">
      <router-link
        v-for="(subNavItem, index) in subNavItems"
        :key="index"
        class="link"
        :to="subNavItem.url"
        @click="clearSubNavItems"
      >
        <div class="line">
          <!-- <GoogleSymbol symbol="menu" size="24px" :hover="false" class="icon" /> -->
          <span class="label">{{ subNavItem.libelle }}</span>
        </div>
      </router-link>
    </div>
  </div>

  <div
    class="overlay"
    v-if="
      (sidenavIsVisible && subNavItems.length > 0) || (!sidenavIsVisible && sidenavOpen)
    "
    @click="clearSubNavItemsAndClose"
    @mouseover="clearSubNavItemsAndClose"
  ></div>
</template>

<script>
import GoogleSymbol from "./GoogleSymbol.vue";

export default {
  name: "side-nav",
  data: () => {
    return {
      forceSidenavClose: false,
      subNavItems: [],
      activeIndex: null,
      sidenavOpen: false,
    };
  },
  components: {
    GoogleSymbol,
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.auth.isAuthenticated;
    },
    menuUtilisateur() {
      return this.$store.state.api.persistantData.menuUtilisateur;
    },
    sidenavIsVisible() {
      return this.$store.state.sidenav.sidenavIsVisible;
    },
  },
  methods: {
    deconnexion() {
      this.$store.dispatch("auth/deconnexion");

      this.$router.push({ name: "connexion" });
    },
    clearSubNavItems() {
      this.subNavItems = [];
      this.activeIndex = null;
    },
    setActiveAndSubNavItems(items, index) {
      this.activeIndex = index;
      this.subNavItems = items;
    },
    clearSubNavItemsAndClose() {
      this.clearSubNavItems();
      this.closeSidenav();
    },
    openSidenav() {
      this.sidenavOpen = true;
    },
    closeSidenav() {
      this.sidenavOpen = false;
    },
  },
  watch: {
    sidenavIsVisible(newVal) {
      if (newVal === false) {
        this.sidenavOpen = false;
      }
    }
  },
  created() {
    this.$store.dispatch("api/get", {
      url: "menuUtilisateur",
      apiIndex: "menuUtilisateur",
      isPersistantData: true,
    });
  },
};
</script>

<style lang="less" scoped>
#nav {
  position: fixed;
  left: 0;
  top: 64px;
  background-color: @white;
  height: calc(100vh - 64px);
  z-index: 900;
  .flex-row-v-center();
}

.nav-container,
.subnav-container {
  height: 100%;
  width: @sidenav-width;
  overflow: hidden;
  z-index: 1000;
  padding: 12px;

  padding: 12px;
  .flex-col();

  &:hover {
    transform: translateX(0);

    .link {
      transform: translateX(0);

      .text {
        opacity: 1;
      }
    }
  }

  .link {
    cursor: pointer;

    .line {
      padding: 0px 24px;
      border-radius: 100px;
      height: 56px;

      .flex-row-v-center();

      &:hover {
        background: rgba(200, 200, 200, 0.4);
      }

      .icon {
        margin-right: 18px;
      }

      .label {
        font-size: 14px;
        font-family: @roboto-bold;
        font-weight: 700;
        text-decoration: none;
        line-height: 20px;
        color: @font-black;
        letter-spacing: 0.2px;
      }
    }

    &.active {
      .line {
        background: rgba(200, 200, 200, 0.4);
      }
    }
  }
}

.subnav-container {
  min-width: @sidenav-width;
  width: auto;
  background-color: rgba(220, 220, 220, 0.4);
}

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 64px;
  height: calc(100vh - 64px);
  width: 100%;
  z-index: 850;
}

// ANIMATION

@animation-time: 250ms;
@animation-type: ease;

#nav {
  transition: transform @animation-time @animation-type;
  transform: translateX(calc(-@sidenav-width + 65px));

  .line {
    &:hover {
      background: none;
      transition: background @animation-time @animation-type;
    }
    .label {
      opacity: 0;
      transition: opacity 1000ms @animation-type;
    }

    .icon-container {
      transform: translateX(@sidenav-width - 80px);
      transition: transform @animation-time @animation-type;
    }
  }
}

#nav.sidenavIsVisible,
#nav.sidenavOpen {
  transform: translateX(0);

  .line {
    &:hover {
      background: rgba(200, 200, 200, 0.4);
    }
    .label {
      opacity: 1;
    }

    .icon-container {
      transform: translateX(0);
    }
  }
}
</style>

import { API } from "../libs/api";

async function get(url, params) {
  return await API.get(url, { params: params });
}

async function post(url, data) {
  return await API.post(url, data);
}

async function put(url, data) {
  return await API.put(url, data);
}

async function patch(url, data) {
  return await API.patch(url, data);
}

async function _delete(url) {
  return await API.delete(url);
}

export default {
  get,
  post,
  put,
  patch,
  _delete
};

import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
import { ERROR_TYPE } from "../libs/notification";

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "Accueil",
    },
    component: Home,
  },
  {
    path: "/connexion",
    name: "connexion",
    meta: {
      layout: "empty",
    },
    component: () => import("../views/Connexion.vue"),
  },
  {
    path: "/components-list",
    name: "components-list",
    meta: {
      layout: "empty",
    },
    component: () => import("../views/Dev/ComponentsList.vue"),
  },

  // PERSONNEL

  {
    path: "/personnel/:id/organigramme",
    name: "personnelOrganigramme",
    meta: {
      title: "Organigramme pour Personnel",
      url: "organigramme",
      tableName: "Organigramme",
      apiIndex: "Organigramme",
      linkTableUrl: "personnel_Organigramme",
      firstTable: "personnel",
      secondTable: "organigramme",
    },
    component: () => import("../views/Default/SubtableAdd.vue"),
  },
  {
    path: "/personnel/:id/poste",
    name: "personnelPoste",
    meta: {
      title: "Métier pour Personnel",
      url: "poste",
      tableName: "Poste",
      apiIndex: "Poste",
      linkTableUrl: "personnel_Poste",
      firstTable: "personnel",
      secondTable: "poste",
    },
    component: () => import("../views/Default/SubtableAdd.vue"),
  },
  {
    path: "/personnel/:id?",
    name: "personnel",
    meta: {
      title: "Personnel",
      apiUrl: "personnel",
      tableName: "Personnel",
      apiIndex: "Personnel",
    },
    component: () => import("../views/Default/CreateOrEdit.vue"),
  },
  {
    path: "/personnels",
    name: "personnels",
    meta: {
      title: "Liste Personnel",
      createUrl: "personnel",
    },
    component: () => import("../views/Default/Listing.vue"),
  },

  // METIER

  {
    path: "/metier/:id?",
    name: "metier",
    meta: {
      title: "Metier",
      apiUrl: "metier",
      tableName: "Metier",
      apiIndex: "Metier",
    },
    component: () => import("../views/Default/CreateOrEdit.vue"),
  },
  {
    path: "/metiers",
    name: "metiers",
    meta: {
      title: "Liste Metier",
      createUrl: "metier",
    },
    component: () => import("../views/Default/Listing.vue"),
  },

  // POSTE

  {
    path: "/poste/:id?",
    name: "poste",
    meta: {
      title: "Poste",
      apiUrl: "poste",
      tableName: "Poste",
      apiIndex: "Poste",
    },
    component: () => import("../views/Default/CreateOrEdit.vue"),
  },
  {
    path: "/postes",
    name: "postes",
    meta: {
      title: "Liste Poste",
    },
    component: () => import("../views/Default/Listing.vue"),
  },

  // ORGANIGRAMME

  {
    path: "/organigramme/:id?",
    name: "organigramme",
    meta: {
      title: "Organigramme",
      apiUrl: "organigramme",
      tableName: "Organigramme",
      apiIndex: "Organigramme",
    },
    component: () => import("../views/Default/CreateOrEdit.vue"),
  },
  {
    path: "/organigrammes",
    name: "organigrammes",
    meta: {
      title: "Liste Organigramme",
      createUrl: "organigramme",
    },
    component: () => import("../views/Default/Listing.vue"),
  },

  // ACTIVITE

  {
    path: "/activite/:id?",
    name: "activite",
    meta: {
      title: "Activite",
      apiUrl: "activite",
      tableName: "Activite",
      apiIndex: "Activite",
    },
    component: () => import("../views/Default/CreateOrEdit.vue"),
  },
  {
    path: "/activites",
    name: "activites",
    meta: {
      title: "Liste Activités",
      createUrl: "activite",
    },
    component: () => import("../views/Default/Listing.vue"),
  },

  {
    path: "/typeActivite/:id?",
    name: "typeActivite",
    meta: {
      title: "TypeActivite",
      apiUrl: "typeActivite",
      tableName: "TypeActivite",
      apiIndex: "TypeActivite",
    },
    component: () => import("../views/Default/CreateOrEdit.vue"),
  },
  {
    path: "/typeActivites",
    name: "typeActivites",
    meta: {
      title: "Liste Type Activités",
      createUrl: "typeActivite",
    },
    component: () => import("../views/Default/Listing.vue"),
  },

  {
    path: "/activite/:id/metier",
    name: "activiteMetier",
    meta: {
      title: "Metier par Activite",
      url: "metier",
      tableName: "Metier",
      apiIndex: "Metier",
      linkTableUrl: "activite_Metier",
      firstTable: "activite",
      secondTable: "metier",
    },
    component: () => import("../views/Default/SubtableAdd.vue"),
  },

  {
    path: "/activite/:id/poste",
    name: "activitePoste",
    meta: {
      title: "Poste par Activite",
      url: "poste",
      tableName: "Poste",
      apiIndex: "Poste",
      linkTableUrl: "activite_Poste",
      firstTable: "activite",
      secondTable: "poste",
    },
    component: () => import("../views/Default/SubtableAdd.vue"),
  },

  {
    path: "/activite/:id/personnel",
    name: "activitePersonnel",
    meta: {
      title: "Personnel par Activite",
      url: "personnel",
      tableName: "Personnel",
      apiIndex: "Personnel",
      linkTableUrl: "activite_Personnel",
      firstTable: "activite",
      secondTable: "personnel",
    },
    component: () => import("../views/Default/SubtableAdd.vue"),
  },

  {
    path: "/activite/:id/competence",
    name: "activiteCompetence",
    meta: {
      title: "Competence par Activite",
      url: "competence",
      tableName: "Competence",
      apiIndex: "Competence",
      linkTableUrl: "activite_Competence",
      firstTable: "activite",
      secondTable: "competence",
    },
    component: () => import("../views/Default/SubtableAdd.vue"),
  },

  // Competence

  {
    path: "/competence/:id?",
    name: "competence",
    meta: {
      title: "Competence",
      apiUrl: "competence",
      tableName: "Competence",
      apiIndex: "Competence",
    },
    component: () => import("../views/Default/CreateOrEdit.vue"),
  },
  {
    path: "/competences",
    name: "competences",
    meta: {
      title: "Liste Competences",
      createUrl: "competence",
    },
    component: () => import("../views/Default/Listing.vue"),
  },
  {
    path: "/competence/:id/metier",
    name: "competenceMetier",
    meta: {
      title: "Metier par Competence",
      url: "metier",
      tableName: "Metier",
      apiIndex: "Metier",
      linkTableUrl: "competence_Metier",
      firstTable: "competence",
      secondTable: "metier",
    },
    component: () => import("../views/Default/SubtableAdd.vue"),
  },
  {
    path: "/competence/:id/poste",
    name: "competencePoste",
    meta: {
      title: "Poste par Competence",
      url: "poste",
      tableName: "Poste",
      apiIndex: "Poste",
      linkTableUrl: "competence_Poste",
      firstTable: "competence",
      secondTable: "poste",
    },
    component: () => import("../views/Default/SubtableAdd.vue"),
  },
  {
    path: "/competence/:id/personnel",
    name: "competencePersonnel",
    meta: {
      title: "Personnel par Competence",
      url: "personnel",
      tableName: "Personnel",
      apiIndex: "Personnel",
      linkTableUrl: "competence_Personnel",
      firstTable: "competence",
      secondTable: "personnel",
    },
    component: () => import("../views/Default/SubtableAdd.vue"),
  },

  // Role

  {
    path: "/role/:id?",
    name: "role",
    meta: {
      title: "Role",
      apiUrl: "role",
      tableName: "Role",
      apiIndex: "Role",
    },
    component: () => import("../views/Default/CreateOrEdit.vue"),
  },
  {
    path: "/roles",
    name: "roles",
    meta: {
      title: "Liste Roles",
      createUrl: "role",
    },
    component: () => import("../views/Default/Listing.vue"),
  },

  // Profil

  {
    path: "/profil/:id?",
    name: "profil",
    meta: {
      title: "Profil",
      apiUrl: "profil",
      tableName: "Profil",
      apiIndex: "Profil",
    },
    component: () => import("../views/Default/CreateOrEdit.vue"),
  },
  {
    path: "/profils",
    name: "profils",
    meta: {
      title: "Liste Profils",
      createUrl: "profil",
    },
    component: () => import("../views/Default/Listing.vue"),
  },

  // Menu

  {
    path: "/menu/:id?",
    name: "menu",
    meta: {
      title: "Menu",
      apiUrl: "menu",
      tableName: "Menu",
      apiIndex: "Menu",
    },
    component: () => import("../views/Default/CreateOrEdit.vue"),
  },
  {
    path: "/menus",
    name: "menus",
    meta: {
      title: "Liste Menus",
      createUrl: "menu",
    },
    component: () => import("../views/Default/Listing.vue"),
  },

  // Menu_LienMenu

  {
    path: "/menu/:id/lienMenu",
    name: "menuLienMenu",
    meta: {
      title: "LienMenu par Menu",
      url: "lienMenu",
      tableName: "LienMenu",
      apiIndex: "LienMenu",
      linkTableUrl: "menu_LienMenu",
      firstTable: "menu",
      secondTable: "lienMenu",
    },
    component: () => import("../views/Default/SubtableAdd.vue"),
  },

  // GroupeMenu

  {
    path: "/groupeMenu/:id?",
    name: "groupeMenu",
    meta: {
      title: "GroupeMenu",
      apiUrl: "groupeMenu",
      tableName: "GroupeMenu",
      apiIndex: "GroupeMenu",
    },
    component: () => import("../views/Default/CreateOrEdit.vue"),
  },
  {
    path: "/groupeMenus",
    name: "groupeMenus",
    meta: {
      title: "Liste GroupeMenus",
      createUrl: "groupeMenu",
    },
    component: () => import("../views/Default/Listing.vue"),
  },

  // GroupeMenu_LienMenu

  {
    path: "/groupeMenu/:id/lienMenu",
    name: "groupeMenuLienMenu",
    meta: {
      title: "LienMenu par GroupeMenu",
      url: "lienMenu",
      tableName: "LienMenu",
      apiIndex: "LienMenu",
      linkTableUrl: "groupeMenu_LienMenu",
      firstTable: "groupeMenu",
      secondTable: "lienMenu",
    },
    component: () => import("../views/Default/SubtableAdd.vue"),
  },

  // Utilisateur

  {
    path: "/utilisateur/:id?",
    name: "utilisateur",
    meta: {
      title: "Utilisateur",
      apiUrl: "utilisateur",
      tableName: "Utilisateur",
      apiIndex: "Utilisateur",
    },
    component: () => import("../views/Default/CreateOrEdit.vue"),
  },
  {
    path: "/utilisateurs",
    name: "utilisateurs",
    meta: {
      title: "Liste Utilisateurs",
      createUrl: "utilisateur",
    },
    component: () => import("../views/Default/Listing.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function enableUnsavedDesignDialog() {
  store.dispatch("dialog/showUnsavedDesignDataDialog");

  return new Promise((resolve) => {
    let unsubscribeFn = store.subscribe((mutation) => {
      if (mutation.type === "dialog/setButtonValue") {
        unsubscribeFn();
        resolve(mutation.payload);
      }
    });
  });
}

router.beforeEach(async (to, from, next) => {
  const userIsAuthenticated = store.state.auth.isAuthenticated;

  if (
    store.state.design.designCenterIsVisible === true &&
    store.getters["design/designStructureHasChanged"] === true
  ) {
    const dialogResult = await enableUnsavedDesignDialog();
    if (!dialogResult) return next(false);
  }

  store.commit("design/resetState");
  store.commit("api/resetState");
  store.commit("design/SET_ROUTE", to.name);

  if (!userIsAuthenticated && to.name !== "connexion") {
    store.dispatch("notification/add", {
      type: ERROR_TYPE,
      message: "Vous devez être connecté pour accèder à cette ressource.",
      time: new Date(),
    });

    next({
      name: "connexion",
      params: {
        nextUrl: to.fullPath,
      },
    });
  } else {
    next();
  }
});

export default router;

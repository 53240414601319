import cryptoJs from "crypto-js";

const errorsTranslations = {
  "Request failed with status code 401":
    "Vous n'avez pas accès à cette ressource",
};

export const getErrorMessage = ({ message, status, stack }) => {
  if (errorsTranslations[message]) {
    return errorsTranslations[message];
  }

  if (!status && !stack) return message;

  return "Une erreure est survenue";
};

export const getEncryptedError = (data) => {
  return cryptoJs.AES.encrypt(JSON.stringify(data), "protoclient").toString();
};

export const getDecryptedError = (data) => {
  return cryptoJs.AES.decrypt(data, "protoclient").toString(cryptoJs.enc.Utf8);
};

import { createStore } from "vuex";
import authModule from "./auth.module";
import notificationModule from "./notification.module";
import apiModule from "./api.module";
import designModule from "./design.module";
import tooltipModule from "./tooltip.module";
import dialogModule from "./dialog.module";
import contextualMenuModule from "./contextualmenu.module";
import sidenavModule from "./sidenav.module"

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: authModule,
    notification: notificationModule,
    api: apiModule,
    design: designModule,
    tooltip: tooltipModule,
    dialog: dialogModule,
    contextualmenu: contextualMenuModule,
    sidenav: sidenavModule,
  },
});

<template>
  <div
    v-if="!designCenterIsVisible || isDesignButtons"
    class="floating-container"
    :style="{
      bottom: bottom ? bottom : 'unset',
      right: right ? right : 'unset',
      top: top ? top : 'unset',
      left: left ? left : 'unset',
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "floating-container",
  props: {
    bottom: {
      type: String,
      required: false,
    },
    right: {
      type: String,
      required: false,
    },
    top: {
      type: String,
      required: false,
    },
    left: {
      type: String,
      required: false,
    },
    isDesignButtons: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    designCenterIsVisible() {
      return this.$store.state.design.designCenterIsVisible;
    },
  },
};
</script>

<style lang="less" scoped>
.floating-container {
  position: fixed;
  z-index: 1000;

  .flex-row();
}
</style>
const getDefaultState = () => {
  return {
    show: false,
    title: "",
    content: "",
    symbol: null,
    buttons: [],
    buttonValue: null,
    componentName: null,
  };
};

const state = getDefaultState();

const actions = {
  showDialog({ commit }) {
    commit("setShow", true);
  },
  hideDialog({ commit }) {
    commit("setShow", false);
  },
  resetDialog({ commit, dispatch }) {
    dispatch("hideDialog");
    commit("resetState");
  },
  setButtonValue({ commit, dispatch }, buttonValue) {
    commit("setButtonValue", buttonValue);
    dispatch("resetDialog");
  },
  setButtons({ commit }, buttons) {
    commit("setButtons", buttons);
  },
  showUnsavedDesignDataDialog({ commit, dispatch }) {
    commit("setTitle", "Changement non sauvegardé");
    commit(
      "setContent",
      "Les changements apporté au design non pas été sauvegardé, souhaitez vous poursuivre sans sauvegarder ?"
    );
    commit("setSymbol", "warning");
    dispatch("setButtons", [
      { label: "Annuler", value: false },
      { label: "Continuer sans sauvegarder", value: true },
    ]);

    dispatch("showDialog");
  },
  setComponentName({ commit }, componentName) {
    commit("setComponentName", componentName);
  },
};

const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },
  setShow(state, show) {
    state.show = show;
  },
  setTitle(state, title) {
    state.title = title;
  },
  setContent(state, content) {
    state.content = content;
  },
  setSymbol(state, symbol) {
    state.symbol = symbol;
  },
  setButtons(state, buttons) {
    state.buttons = buttons;
  },
  setButtonValue(state, buttonValue) {
    state.buttonValue = buttonValue;
  },
  setComponentName(state, componentName) {
    state.componentName = componentName;
  },
};

const getters = {};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};

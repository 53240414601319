<template>
  <div class="dummy-div-for-transition">
    <transition name="slide-fade" appear>
      <div
        class="contextual-menu"
        :class="{
          left: left ? true : false,
        }"
        :style="{
          top: contextualMenuY + 'px',
          left: contextualMenuX + 'px',
        }"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "contextual-menu-basic-component",
  computed: {
    contextualMenuX() {
      return this.$store.state.contextualmenu.x;
    },
    contextualMenuY() {
      return this.$store.state.contextualmenu.y;
    },
  },
  props: {
    left: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" scoped>
.contextual-menu {
  position: fixed;
  border-radius: 12px;
  background-color: @white;
  z-index: 910;

  .shadow-level-3();

  &.left {
    transform: translateX(-100%);
  }

  &.slide-fade-enter-from,
  &.slide-fade-leave-to {
    transform: translateY(-10px) translateX(-100%);
    opacity: 0;
  }

  &.slide-fade-enter-to,
  &.slide-fade-leave-from {
    transform: translateY(0px) translateX(-100%);
    opacity: 1;
  }

  &.slide-fade-enter-active,
  &.slide-fade-leave-active {
    transition: transform 550ms ease, opacity 550ms ease;
  }
}
</style>
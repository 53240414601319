<template>
  <div class="dialog-container" v-if="show">
    <div class="dialog">
      <div class="top-container">
        <!-- SYMBOL -->

        <GoogleSymbol
          v-if="symbol"
          :symbol="symbol"
          :size="'29px'"
          :hover="false"
          class="symbol"
        />

        <!-- TITLE -->

        <div class="title">{{ title }}</div>

        <!-- CONTENT -->

        <div class="content">{{ content }}</div>
      </div>
      <div class="button-container">
        <div
          class="action-button"
          v-for="(button, index) in buttons"
          :key="index"
          @click="buttonAction(button.value, button.callback)"
        >
          {{ button.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleSymbol from "@/components/GoogleSymbol";

export default {
  name: "dialog-component",
  components: { GoogleSymbol },
  computed: {
    show() {
      return this.$store.state.dialog.show;
    },
    symbol() {
      return this.$store.state.dialog.symbol;
    },
    title() {
      return this.$store.state.dialog.title;
    },
    content() {
      return this.$store.state.dialog.content;
    },
    buttons() {
      return this.$store.state.dialog.buttons;
    },
  },
  methods: {
    buttonAction(value, callback) {
      this.$store.dispatch("dialog/setButtonValue", value);

      if (callback) callback();
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.15);

  .flex-center();

  .dialog {
    background-color: @white;
    border-radius: 28px;
    max-width: 382px;

    .top-container {
      padding: 24px 34px 0 34px;
      .flex-col-h-center();

      .symbol {
        margin-bottom: 16px;
      }

      .title {
        color: @title-font-black;
        margin-bottom: 16px;
        .title-small-font();
      }

      .content {
        .body-medium-font();
        color: @input-label-font-black;
      }
    }

    .button-container {
      height: 88px;
      width: 100%;
      padding: 24px;
      .flex-row-h-right();

      .action-button {
        height: 40px;
        margin-left: 8px;
        padding: 10px 12px;
        cursor: pointer;
        border-radius: 6px;
        .label-large-font();

        &:hover {
          background-color: rgba(0, 0, 0, 0.07);
        }
      }
    }
  }
}
</style>
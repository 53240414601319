import axios from "axios";
import { getAuthorizationToken } from "./cookie";
import store from "../store";
import { ERROR_TYPE } from "../libs/notification";
import { baseUrl } from "../config/global-config"

const initApi = () => {
  let api = axios.create({
    baseURL: baseUrl,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });

  api.defaults.headers.common["Authorization"] = getAuthorizationToken();

  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const jsonError = error.toJSON();

      store.dispatch("notification/add", {
        type: ERROR_TYPE,
        message: jsonError.message,
        stack: jsonError.stack,
        status: jsonError.status,
        time: new Date(),
      });
      return false;
    }
  );

  return api;
};

export const API = initApi();

export const setAuthorizationHeader = () => {
  API.defaults.headers.common["Authorization"] = getAuthorizationToken();
};

export const removeAuthorizationHeader = () => {
  API.defaults.headers.common["Authorization"] = "";
};

<template>
  <component :is="layout" />
</template>

<script>
export default {
  name: "App",
  computed: {
    layout() {
      if (this.$route.name === undefined) return "loading-layout";
      return (this.$route.meta.layout || "default") + "-layout";
    },
  },
};
</script>

<style lang="less">
// @font-face {
//   font-family: "OpenSans";
//   src: url("assets/fonts/OpenSans-Regular.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Montserrat-Bold";
//   src: url("assets/fonts/Montserrat-Bold.ttf") format("truetype");
// }

@font-face {
  font-family: "Material Symbols";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/MaterialSymbolsOutlined.ttf") format("truetype");
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/MaterialIcons-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/MaterialIconsOutlined-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Roboto Regular";
  font-weight: 400;
  src: url("assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Medium";
  font-weight: 500;
  src: url("assets/fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Bold";
  font-weight: 700;
  src: url("assets/fonts/Roboto-Bold.ttf") format("truetype");
}

#app {
  font-family: @text-font, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: @grey-font;

  background-color: @background-grey;

  min-height: 100vh;
}
</style>

import { API } from "../libs/api";
import {
  setAuthorization,
  removeAuthorization,
  getAuthorizationToken,
} from "../libs/cookie";
import store from "../store";
import { ERROR_TYPE } from "../libs/notification";

async function profil() {
  return await API.get("/authentication/profil");
}

async function connexion(username, password) {
  const returnData = await API.post("/authentication/login", {
    username: username,
    password: password,
  });

  if (!returnData) return false;

  if (!returnData.data.success) {
    store.dispatch("notification/add", {
      type: ERROR_TYPE,
      message: returnData.data.msg,
      time: new Date(),
    });
    return false;
  }

  setAuthorization(returnData.data.token, returnData.data.expiresIn);

  return true;
}

function deconnexion() {
  removeAuthorization();
}

function isAuthenticated() {
  return !!getAuthorizationToken();
}

export default {
  profil,
  connexion,
  deconnexion,
  isAuthenticated,
};

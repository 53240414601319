<template>
  <component :is="componentName"></component>
</template>

<script>
import AccountContextualMenu from "@/components/AccountContextualMenu";

export default {
  name: "global-contextual-menu",
  components: { AccountContextualMenu },
  computed: {
    componentName() {
      return this.$store.state.contextualmenu.componentName;
    },
  },
};
</script>
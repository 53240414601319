<template>
  <div
    class="floating-button"
    :class="[type]"
  >
    <GoogleSymbol
      v-if="symbol"
      :size="'24px'"
      :symbol="symbol"
      :hover="false"
      class="symbol"
      :color="'white'"
    />

    <span class="label">{{ label }}</span>
  </div>
</template>

<script>
import GoogleSymbol from "@/components/GoogleSymbol.vue";

export default {
  name: "floating-button-component",
  components: { GoogleSymbol },
  props: {
    label: {
      type: String,
      required: true,
    },
    symbol: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: "action",
    },
  },
};
</script>

<style lang="less" scoped>
.floating-button {
  height: 56px;
  padding: 0 20px;
  width: auto;
  user-select: none;
  cursor: pointer;
  border-radius: 16px;

  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);

  .symbol {
    margin-right: 8px;
    color: @white;
  }

  .label {
    .label-large-font();
  }

  .flex-center();

  &.action {
    background-color: @blue;
    color: @white;
  }

  &.cancel {
    background-color: @cancel-red;
    color: @white;
  }

  &.validate {
    background-color: @green;
    color: @white;
  }
}
</style>
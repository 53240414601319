<template>
  <div id="toaster-container">
    <Toaster
      v-for="(notification, index) in notifications"
      :key="index"
      :message="notification.message"
      :index="index"
      :show="notification.displayInToaster"
    />
  </div>
</template>

<script>
import Toaster from "@/components/Toaster";

export default {
  name: "toaster-container",
  components: { Toaster },
  computed: {
    notifications() {
      return this.$store.state.notification.notifications;
    },
  },
};
</script>

<style lang="less" scoped>
#toaster-container {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1100;

  .flex-col();
}
</style>
<template>
  <div id="topbar">
    <GoogleSymbol
      :symbol="sidenavIcon"
      size="24px"
      padding="12px"
      :hover="true"
      class="menu-icon theme"
      @click="toggleSideNav"
    />
    <span class="title">{{ title }}</span>

    <div class="spacer"></div>

    <GoogleSymbol
      :symbol="'brush'"
      :size="'24px'"
      class="brush-icon theme"
      @click="toggleDesignCenter"
      :tooltip="'Design'"
      :padding="'12px'"
      :selected="designCenterIsVisible"
    />

    <GoogleSymbol
      :symbol="'notifications'"
      :size="'24px'"
      :padding="'12px'"
      @click="toggleNotificationCenter"
      class="notification-icon theme"
      :selected="notificationCenterIsVisible"
    />

    <GoogleSymbol
      :symbol="'account_circle'"
      :size="'24px'"
      :padding="'12px'"
      class="account-icon theme"
      tooltip="Profile"
      @click="showAccountInfo"
    />
  </div>
</template>

<script>
import GoogleSymbol from "@/components/GoogleSymbol.vue";

export default {
  name: "topbar",
  components: { GoogleSymbol },
  computed: {
    title() {
      return this.$route.meta.title;
    },
    designCenterIsVisible() {
      return this.$store.state.design.designCenterIsVisible;
    },
    dialogIsVisible() {
      return this.$store.state.dialog.show;
    },
    dialogButtonValue() {
      return this.$store.state.dialog.buttonValue;
    },
    contextualMenuIsVisible() {
      return !!this.$store.state.contextualmenu.componentName;
    },
    notificationCenterIsVisible() {
      return this.$store.state.notification.notificationCenterIsVisible;
    },
    sidenavIsVisible() {
      return this.$store.state.sidenav.sidenavIsVisible;
    },
    sidenavIcon() {
      if (this.sidenavIsVisible) {
        return "menu_open";
      }
      return "menu";
    },
  },
  methods: {
    toggleNotificationCenter() {
      if (this.notificationCenterIsVisible) {
        this.$store.dispatch("notification/hideNotificationCenter");
      } else {
        this.$store.dispatch("notification/showNotificationCenter");
      }
    },
    async toggleDesignCenter() {
      if (!this.designCenterIsVisible) {
        this.$store.dispatch("design/showDesignCenter");
      } else {
        if (this.$store.getters["design/designStructureHasChanged"]) {
          this.$store.dispatch("dialog/showUnsavedDesignDataDialog");
          this.$store.dispatch("dialog/setButtons", [
            {
              label: "Annuler",
              value: false,
            },
            {
              label: "Continuer sans sauvegarder",
              value: true,
              callback: () => {
                this.$store.dispatch("design/resetOriginalDesignStructure");
                this.$store.dispatch("design/hideDesignCenter");
              },
            },
          ]);
        } else {
          this.$store.dispatch("design/hideDesignCenter");
        }
      }
    },
    showAccountInfo(e) {
      const boundingRect = e.target.getBoundingClientRect();

      if (!this.contextualMenuIsVisible) {
        this.$store.dispatch("contextualmenu/setCoord", {
          x: boundingRect.x + boundingRect.width / 2,
          y: boundingRect.y + boundingRect.height,
        });

        this.$store.dispatch("contextualmenu/setComponentName", "AccountContextualMenu");
      } else {
        this.$store.dispatch("contextualmenu/setComponentName", null);
      }
    },
    toggleSideNav() {
      this.$store.dispatch("sidenav/toggleSideNavVisibility");
    },
  },
};
</script>

<style lang="less" scoped>
#topbar {
  height: @topbar-height;
  width: 100%;
  background-color: @theme-background-color;
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;
  color: @theme-background-color;
  // .shadow-level-2();
  .flex-row-v-center();
  .flex-row-h-right();

  .title {
    margin-left: 12px;
    // text-transform: uppercase;
    // font-size: 0.9rem;
    font-family: @roboto-regular;
    font-size: 18px;
    line-height: 28px;
    color: @theme-font-color;
  }

  .spacer {
    flex-grow: 1;
  }

  .account-icon,
  .notification-icon,
  .brush-icon,
  .save-button {
    margin-right: 12px;
  }

  .menu-icon {
    margin-left: 12px;
  }
}
</style>

const state = {
  sidenavIsVisible: false
};

const actions = {
  toggleSideNavVisibility({ commit, state }) {
    if (state.sidenavIsVisible === true) {
      commit("setSidenavVisibility" , false);
    } else {
      commit("setSidenavVisibility", true);
    }
  },

  setSidenavVisibility({ commit }, visibility) {
    commit("setSidenavVisibility", visibility);
  }
};

const mutations = {
  setSidenavVisibility(state, visibility) {
    state.sidenavIsVisible = visibility;
  }
};

const getters = {};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
};

import authService from "../services/auth.service";

const state = {
  isAuthenticated: authService.isAuthenticated(),
};

const actions = {
  async connexion(ctx, { username, password }) {
    const retValue = await authService.connexion(username, password);

    if (!retValue) return false;

    ctx.commit("setIsAuthenticated", true);
  },

  async profil() {
    await authService.profil();
  },

  deconnexion(ctx) {
    authService.deconnexion();

    ctx.commit("setIsAuthenticated", false);
  },
};

const mutations = {
  setIsAuthenticated(state, boolean) {
    state.isAuthenticated = boolean;
  },
};

const getters = {};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};

<template>
  <div class="dialog-container" v-if="show" @click="emitOverlayClick">
    <transition name="slide-fade" appear>
      <div class="dialog">
        <div class="top-container">
          <!-- SYMBOL -->

          <GoogleSymbol
            v-if="symbol"
            :symbol="symbol"
            :size="'29px'"
            :hover="false"
            class="symbol"
          />

          <!-- TITLE -->

          <div class="title" :class="{ 'align-center': symbol !== null }">
            {{ title }}
          </div>

          <!-- CONTENT -->

          <div class="content">
            <slot name="content" />
          </div>
        </div>

        <!-- BUTTONS -->

        <div class="button-container">
          <slot name="buttons" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import GoogleSymbol from "@/components/GoogleSymbol";

export default {
  name: "dialog-basic-component",
  components: { GoogleSymbol },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    symbol: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  methods: {
    emitOverlayClick(e) {
      if (e.target.classList.contains("dialog-container")) {
        return this.$emit("overlayClick");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.35);

  .flex-center();

  .dialog {
    background-color: @white;
    border-radius: 28px;
    max-width: 382px;
    width: 80%;

    &.slide-fade-enter-from,
    &.slide-fade-leave-to {
      transform: translateY(50px);
    }

    &.slide-fade-enter-to,
    &.slide-fade-leave {
      transform: translateY(0px);
    }

    &.slide-fade-enter-active,
    &.slide-fade-leave-active {
      transition: transform 450ms ease;
    }

    .top-container {
      padding: 24px 34px 0 34px;
      .flex-col();

      .symbol {
        margin-bottom: 16px;
      }

      .title {
        color: @title-font-black;
        margin-bottom: 16px;
        .title-small-font();
      }
    }

    .button-container {
      height: 88px;
      width: 100%;
      padding: 24px;
      .flex-row-h-right();

      .action-button {
        height: 40px;
        margin-left: 8px;
        padding: 10px 12px;
        cursor: pointer;
        border-radius: 6px;
        .label-large-font();

        &:hover {
          background-color: rgba(0, 0, 0, 0.07);
        }
      }
    }
  }
}
</style>
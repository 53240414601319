<template>
  <!-- CONTAINER -->

  <ContextualMenu :left="true">
    <div class="contextual-menu" @click="disconnect">
      <!-- LINE -->

      <div class="line">
        <GoogleSymbol
          :symbol="'logout'"
          :size="'29px'"
          :hover="false"
          class="symbol"
        />
        <span class="label">Déconnexion</span>
      </div>

      <!--  -->
    </div>
  </ContextualMenu>

  <!--  -->
</template>

<script>
import ContextualMenu from "@/components/basics/ContextualMenu";
import GoogleSymbol from "@/components/GoogleSymbol";

export default {
  name: "account-contextual-menu",
  components: {
    ContextualMenu,
    GoogleSymbol,
  },
  methods: {
    disconnect() {
      this.$store.dispatch("contextualmenu/setComponentName", null);

      this.$store.dispatch("auth/deconnexion");

      this.$router.push({ name: "connexion" });
    },
  },
};
</script>

<style lang="less" scoped>
.contextual-menu {
  cursor: pointer;
  padding: 6px 0px;

  .line {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 24px;

    .flex-row-v-center();

    .label {
      margin-left: 12px;
    }
  }
}
</style>
<template>
  <div
    class="tooltip"
    ref="tooltip"
    :style="{
      top: getTopPosition,
      bottom: getBottomPosition,
      right: getRightPosition,
      left: getLeftPosition,
    }"
    :class="{
      active: show,
      [placement]: true,
    }"
  >
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "tooltip",
  data() {
    return {
      spacing: 5,
    };
  },
  computed: {
    coord() {
      return this.$store.state.tooltip.coord;
    },
    message() {
      return this.$store.state.tooltip.message;
    },
    placement() {
      return this.$store.state.tooltip.placement;
    },
    show() {
      return this.$store.state.tooltip.show;
    },
    getCoord() {
      if (this.coord == null) return {};
      return this.coord;
    },
    getTopPosition() {
      if (this.placement == "bottom") {
        return `${this.getCoord.y + this.getCoord.height + this.spacing}px`;
      } else if (this.placement == "left" || this.placement == "right") {
        return `${this.getCoord.y + this.getCoord.height / 2}px`;
      }
      return "unset";
    },
    getBottomPosition() {
      if (this.placement == "top") {
        return `${window.innerHeight - this.getCoord.y + this.spacing}px`;
      }
      return "unset";
    },
    getRightPosition() {
      if (this.placement == "top" || this.placement == "bottom") {
        return `${
          window.innerWidth - this.getCoord.x - this.getCoord.width / 2
        }px`;
      } else if (this.placement == "left") {
        return `${window.innerWidth - this.getCoord.x + this.spacing}px`;
      }
      return "unset";
    },
    getLeftPosition() {
      if (this.placement == "right") {
        return `${this.getCoord.x + this.getCoord.width + this.spacing}px`;
      }
      return "unset";
    },
  },
  methods: {
    updatePlacementIfOutOfView() {
      const tooltip = this.$refs.tooltip.getBoundingClientRect();

      if (this.placement == "right") {
        if (tooltip.x + tooltip.width > window.innerWidth) {
          this.$store.dispatch("tooltip/setPlacement", "left");
          return false;
        }
      } else if (this.placement == "left") {
        if (tooltip.x < 0) {
          this.$store.dispatch("tooltip/setPlacement", "right");
          return false;
        }
      } else if (this.placement == "top") {
        if (tooltip.y < 0) {
          this.$store.dispatch("tooltip/setPlacement", "bottom");
          return false;
        } else if (tooltip.x < 0) {
          this.$store.dispatch("tooltip/setPlacement", "right");
          return false;
        } else if (tooltip.x + tooltip.width > window.innerWidth) {
          this.$store.dispatch("tooltip/setPlacement", "left");
          return false;
        }
      } else if (this.placement == "bottom") {
        if (tooltip.y + tooltip.height > window.innerHeight) {
          this.$store.dispatch("tooltip/setPlacement", "top");
          return false;
        } else if (tooltip.x < 0) {
          this.$store.dispatch("tooltip/setPlacement", "right");
          return false;
        } else if (tooltip.x + tooltip.width > window.innerWidth) {
          this.$store.dispatch("tooltip/setPlacement", "left");
          return false;
        }
      }
      return true;
    },
  },
  updated() {
    if (this.show && this.message) {
      this.updatePlacementIfOutOfView();
    }
  },
};
</script>

<style lang="less" scoped>
.tooltip {
  position: fixed;
  pointer-events: none;
  user-select: none;
  background: rgba(51, 51, 51, 0.9);
  padding: 10px 15px;
  color: @white;
  z-index: 9999;
  border-radius: 5px;
  opacity: 0;
  transition: none;
  text-align: center;
  .label-large-font();

  &.top,
  &.bottom {
    transform: translateX(50%);
  }

  &.left,
  &.right {
    transform: translateY(-50%);
  }

  &.active {
    opacity: 1;
    transition: opacity 200ms ease;
  }
}
</style>
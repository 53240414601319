const state = {
  coord: null,
  message: null,
  placement: "top",
  show: false,
};

const actions = {
  setTooltip(
    { commit, dispatch },
    { x, y, width, height, message, placement }
  ) {
    commit("setMessage", message);
    commit("setCoord", { x: x, y: y, width: width, height: height });
    commit("setPlacement", placement);

    dispatch("showTooltip");
  },
  setPlacement({ commit }, placement) {
    commit("setPlacement", placement);
  },
  showTooltip({ commit }) {
    commit("setShow", true);
  },
  hideTooltip({ commit }) {
    commit("setMessage", null);
    commit("setCoord", null);
    commit("setShow", false);
  },
};

const mutations = {
  setCoord(state, coord) {
    state.coord = coord;
  },
  setMessage(state, message) {
    state.message = message;
  },
  setPlacement(state, placement) {
    state.placement = placement;
  },
  setShow(state, show) {
    state.show = show;
  },
};

const getters = {};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};

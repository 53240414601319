export const ERROR_TYPE = "error";

export const typeTranslation = (type) => {
  switch (type) {
    case ERROR_TYPE:
      return "Erreur";

    default:
      return false;
  }
};

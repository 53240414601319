<template>
  <div class="input-container">
    <input
      class="input"
      :type="type"
      name=""
      :id="id"
      @focus="onFocus"
      @blur="onBlur"
      :value="modelValue"
      @input="updateValue"
    />
    <label class="label" :class="{ inputIsFocused }" :for="id">{{
      label
    }}</label>
  </div>
</template>

<script>
export default {
  name: "input-component",
  data() {
    return {
      inputIsFocused: false,
    };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
  },
  methods: {
    onFocus() {
      if (!this.inputIsFocused) this.inputIsFocused = true;
    },
    onBlur() {
      if (this.modelValue.length == 0 && this.inputIsFocused)
        this.inputIsFocused = false;
      else this.inputIsFocused = true;
    },
    updateFocus() {
      if (this.modelValue.length == 0) {
        this.inputIsFocused = false;
      } else {
        this.inputIsFocused = true;
      }
    },
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
  watch: {
    modelValue: function () {
      this.updateFocus();
    },
  },
  created() {
    this.updateFocus();
  },
};
</script>

<style lang="less" scoped>
.input-container {
  position: relative;

  .input {
    width: 100%;
    padding: 16px;
    height: 56px;
    color: @title-font-black;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    display: inline-block;
    .body-medium-font();
  }

  .label {
    position: absolute;
    left: 11px;
    top: -10px;
    background-color: @white;
    padding: 0 5px;
    transform: translateY(28px) scale(1);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    display: inline-block;

    // font-family: @roboto-regular;
    // font-size: 16px;
    // line-height: 24px;
    // letter-spacing: 0.5px;
    .label-large-font();
    color: @input-label-font-black;

    &.inputIsFocused {
      transform: translateY(0) scale(0.9);
    }
  }
}
</style>
<template>
  <div id="content">
    <router-view v-slot="{ Component }" :key="$route.fullPath">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
  <NotificationCenter />
  <ToasterContainer />
</template>

<script>
import NotificationCenter from "../components/NotificationCenter.vue";
import ToasterContainer from "../components/ToasterContainer.vue";

export default {
  components: { NotificationCenter, ToasterContainer },
  name: "empty-layout",
};
</script>

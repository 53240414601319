import apiService from "../services/api.service";

const getDefaultState = () => {
  return {
    data: {},
    checkboxData: {},
    originalCheckboxData: {},
    errors: [],
  };
};

const state = {
  ...getDefaultState(),
  persistantData: {},
};

const actions = {
  async get(
    ctx,
    {
      url,
      params,
      apiIndex,
      setDefaultCheckboxData,
      referenceIndex,
      isPersistantData,
    }
  ) {
    const retValue = await apiService.get(`api/${url}`, params);

    if (!retValue) return false;

    if (setDefaultCheckboxData) {
      ctx.dispatch("setDefaultCheckboxData", {
        data: retValue.data.data,
        apiIndex: apiIndex,
        referenceIndex: referenceIndex,
      });
    }

    if (isPersistantData === true) {
      return ctx.commit("setPersistantData", {
        persistantData: retValue.data.data,
        apiIndex: apiIndex,
      });
    }

    return ctx.commit("setData", {
      data: retValue.data.data,
      apiIndex: apiIndex,
    });
  },

  async create(ctx, { tableName, data }) {
    const retValue = await apiService.put(`api/${tableName}`, data);

    if (!retValue) return false;
    if (!retValue.data.success) {
      ctx.commit("setErrors", retValue.data.errors);
      return false;
    }

    return true;
  },

  async update(ctx, { tableName, data, id }) {
    const retValue = await apiService.patch(`api/${tableName}/${id}`, data);

    if (!retValue) return false;

    if (!retValue.data.success) {
      ctx.commit("setErrors", retValue.data.errors);
      return false;
    }

    return true;
  },

  async delete(ctx, { url, id }) {
    let retValue;

    if (id) retValue = await apiService._delete(`api/${url}/${id}`);
    else retValue = await apiService._delete(`api/${url}`);

    if (!retValue) return false;

    if (!retValue.data.success) {
      ctx.commit("setErrors", retValue.data.errors);
      return false;
    }

    return true;
  },

  setEmptyData({ commit }, { data, apiIndex }) {
    return commit("setData", { data: data, apiIndex: apiIndex });
  },

  setDefaultCheckboxData({ commit }, { data, apiIndex, referenceIndex }) {
    let dataForCheckbox = [];

    for (var i in data) {
      if (data[i][referenceIndex] && data[i][referenceIndex].length > 0) {
        dataForCheckbox.push(data[i]);
      }
    }

    commit("setOriginalCheckboxData", {
      data: [...dataForCheckbox],
      apiIndex: `${apiIndex}`,
    });

    return commit("setCheckboxData", {
      data: dataForCheckbox,
      apiIndex: `${apiIndex}`,
    });
  },

  addData({ commit }, { data, apiIndex }) {
    return commit("addData", { data: data, apiIndex: apiIndex });
  },
  addCheckboxData({ commit }, { data, apiIndex }) {
    return commit("addCheckboxData", { data: data, apiIndex: apiIndex });
  },
  removeDataById({ commit }, { id, apiIndex }) {
    return commit("removeDataById", { id: id, apiIndex: apiIndex });
  },
  removeCheckboxDataById({ commit }, { id, apiIndex }) {
    return commit("removeCheckboxDataById", { id: id, apiIndex: apiIndex });
  },
};

const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },
  setData(state, { data, apiIndex }) {
    state.data[apiIndex] = data;
  },
  setPersistantData(state, { persistantData, apiIndex }) {
    state.persistantData[apiIndex] = persistantData;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  addData(state, { data, apiIndex }) {
    if (!Array.isArray(state.data[apiIndex])) state.data[apiIndex] = [];

    state.data[apiIndex].push(data);
  },
  addCheckboxData(state, { data, apiIndex }) {
    if (!Array.isArray(state.checkboxData[apiIndex]))
      state.checkboxData[apiIndex] = [];

    state.checkboxData[apiIndex].push(data);
  },
  removeDataById(state, { id, apiIndex }) {
    if (!Array.isArray(state.data[apiIndex])) state.data[apiIndex] = [];

    const index = state.data[apiIndex].findIndex((el) => el.id == id);

    if (index !== -1) state.data[apiIndex].splice(index, 1);
  },
  removeCheckboxDataById(state, { id, apiIndex }) {
    if (!Array.isArray(state.checkboxData[apiIndex]))
      state.checkboxData[apiIndex] = [];

    const index = state.checkboxData[apiIndex].findIndex((el) => el.id == id);

    if (index !== -1) state.checkboxData[apiIndex].splice(index, 1);
  },
  setOriginalCheckboxData(state, { data, apiIndex }) {
    state.originalCheckboxData[apiIndex] = data;
  },
  setCheckboxData(state, { data, apiIndex }) {
    state.checkboxData[apiIndex] = data;
  },
};

const getters = {
  getApiDataByApiIndex: (state) => (apiIndex) => {
    if (state.data[apiIndex]) return state.data[apiIndex];
    return [];
  },
  getApiDataByTableNames: (state) => (dataIndex) => {
    let currentData = state.data;

    for (let i in dataIndex.tableNames) {
      currentData = currentData[dataIndex.tableNames[i]];

      if (currentData === undefined) return undefined;
    }

    return currentData;
  },
  getOriginalCheckboxDataByApiIndex: (state) => (apiIndex) => {
    if (state.originalCheckboxData[apiIndex])
      return state.originalCheckboxData[apiIndex];
    return [];
  },
  getCheckboxDataByApiIndex: (state) => (apiIndex) => {
    if (state.checkboxData[apiIndex]) return state.checkboxData[apiIndex];
    return [];
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};

<template>
  <Dialog
    :title="'Création d\'un champ personnalisé'"
    :show="true"
    class="dialog"
  >
    <template v-slot:content>
      <Input :label="'Nom du champ'" :id="'field-name'" class="dialog-input" />
      <Select
        :label="'Type du champ'"
        :id="'field-type'"
        :model-value="'tes'"
        :options="[
          { label: 'Texte', value: 0 },
          { label: 'test', value: 1 },
        ]"
      />
    </template>

    <template v-slot:buttons>
      <div class="action-button" @click="hideDialog">Annuler</div>
      <div class="action-button" @click="hideDialog">Valider</div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/components/basics/Dialog";
import Input from "@/components/basics/Input";
import Select from "@/components/basics/Select";

export default {
  name: "custom-field-dialog",
  components: {
    Dialog,
    Input,
    Select,
  },
  data() {
    return {
      displayDialog: true,
    };
  },
  computed: {
    dialogIsVisible() {
      return !!this.$store.state.dialog.componentName;
    },
  },
  methods: {
    hideDialog() {
      this.$store.dispatch("dialog/setComponentName", null);
    },
  },
};
</script>
<template>
  <div
    v-if="show"
    class="toaster"
    :class="{ animate: animate, sideFade: sideFade }"
  >
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "toaster",
  data() {
    return {
      animate: false,
      sideFade: false,
    };
  },
  props: {
    message: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    getMessageReadingTime() {
      return this.message.length * 200 + 200;
    },
  },
  created() {
    setTimeout(() => {
      this.animate = true;
    }, 1);

    setTimeout(() => {
      this.sideFade = true;
    }, this.getMessageReadingTime);

    setTimeout(() => {
      this.$store.dispatch("notification/hideInToaster", this.index);
    }, this.getMessageReadingTime + 300);
  },
};
</script>

<style lang="less" scoped>
.toaster {
  background-color: @error-red;
  color: @white;
  padding: 20px 30px;
  border-radius: 10px;
  margin-bottom: 10px;
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 300ms ease, transform 300ms ease;

  &.animate {
    opacity: 1;
    transform: translateY(0);

    &.sideFade {
      opacity: 0;
      transform: translateX(100px);
    }
  }
}
</style>
<template>
  <div
    class="icon-container"
    :style="{
      padding: padding,
    }"
    :class="[hover ? 'hover' : '', selected ? 'selected' : '']"
  >
    <i
      :class="[
        color ? color : '',
        background ? `background-${background}` : '',
      ]"
      class="google-symbol material-symbols"
      :style="{
        fontSize: size,
        color,
        height: size,
        width: size,
      }"
      >{{ symbol }}</i
    >
  </div>
</template>

<script>
export default {
  name: "google-symbol",
  props: {
    symbol: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "24px",
    },
    padding: {
      type: String,
      default: "0px",
    },
    color: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: "",
    },
    hover: {
      type: Boolean,
      default: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" scoped>
.icon-container {
  border-radius: 50%;
  cursor: pointer;

  &.hover:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.hover:hover.selected,
  &.selected {
    background-color: rgba(0, 0, 0, 0.15);
  }
}

&.theme {
  .google-symbol {
    color: @theme-font-color;

    &.black {
      color: @black;
    }
  }
}

.google-symbol {
  background-color: @transparent;
  user-select: none;
  color: @font-black;
  display: block;
  text-transform: none;
  font-style: normal;
  pointer-events: none;
  .flex-center();

  &.white {
    color: @white;
  }

  &.red {
    color: @error-red;
  }

  // &.background-blue {
  //   background-color: @blue;

  //   &.hover:hover,
  //   &.selected {
  //     background-color: @blue;
  //     opacity: 0.8;
  //   }
  // }

  // &.background-red {
  //   background-color: @error-red;

  //   &.hover:hover,
  //   &.selected {
  //     background-color: @error-red;
  //     opacity: 0.8;
  //   }
  // }

  // &.background-darker-green {
  //   background-color: @darker-green;

  //   &.hover:hover,
  //   &.selected {
  //     background-color: @darker-green;
  //     opacity: 0.8;
  //   }
  // }
}
</style>

<template>
  <div class="dummy-div">
    <transition name="slide">
      <div id="notification-center" v-if="notificationCenterIsVisible">
        <div class="title">
          <span>Notifications</span>
          <span
            class="material-icons-outlined close"
            @click="closeNotificationCenter"
            >close</span
          >
        </div>
        <div
          class="card-container"
          ref="cardContainer"
          v-if="notifications.length"
        >
          <!-- CARD -->

          <div
            class="card"
            :class="[
              notification.type,
              notification.startRemoveAnimation ? 'start-remove-animation' : '',
            ]"
            v-for="notification in notifications"
            :key="notification.id"
            @mouseover="setNotificationReaded(notification)"
          >
            <div v-if="notification.isNotReaded" class="badge"></div>
            <div class="header">
              <span class="title">{{
                computedNotificationType(notification.type)
              }}</span>

              <GoogleSymbol
                :symbol="'close'"
                class="close"
                :color="'white'"
                :hover="false"
                @click="closeNotification(notification.id)"
              />
            </div>
            <div class="body">
              <span>{{ computedError(notification) }}</span>
            </div>
            <div class="footer">
              <div v-if="notification.status && notification.stack">
                <GoogleSymbol
                  v-if="!notification.expand"
                  :symbol="'expand_more'"
                  :color="'white'"
                  @click="expandMoreNotification(notification.id)"
                />
                <GoogleSymbol
                  v-else
                  :symbol="'expand_less'"
                  :color="'white'"
                  @click="expandLessNotification(notification.id)"
                />
              </div>
              <div class="spacer"></div>
              <span class="time">{{ computedTime(notification.time) }}</span>
            </div>

            <!-- EXPAND -->
            <div
              class="expanded-container"
              :class="{ visible: notification.expand }"
            >
              <span class="subtitle">Code d'erreur :</span>
              <div class="encrypted-error-container">
                {{ notification.encryptedError }}
              </div>
            </div>
          </div>
        </div>

        <div v-else class="no-notification">
          <span>Aucune notification pour le moment</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { getErrorMessage, getDecryptedError } from "../libs/error";
import { typeTranslation } from "../libs/notification";
import moment from "moment";
import GoogleSymbol from "@/components/GoogleSymbol.vue";

export default {
  name: "NotificationCenter",
  components: {
    GoogleSymbol,
  },
  computed: {
    notifications() {
      return this.$store.state.notification.notifications;
    },
    notificationCenterIsVisible() {
      return this.$store.state.notification.notificationCenterIsVisible;
    },
    clonedNotifications: function () {
      return JSON.parse(JSON.stringify(this.notifications));
    },
    numberOfUnreadNotifications: function () {
      return this.$store.getters["notification/numberOfUnread"];
    },
  },
  watch: {
    clonedNotifications: {
      handler: function (after, before) {
        if (after.length > before.length) {
          setTimeout(() => {
            this.$refs.cardContainer.scrollTo({
              left: 0,
              top: this.$refs.cardContainer.scrollHeight * -1,
              behavior: "smooth",
            });
          }, 1);
        }
      },
      deep: true,
    },
  },
  methods: {
    computedError(error) {
      return getErrorMessage(error);
    },
    computedNotificationType(type) {
      return typeTranslation(type);
    },
    computedTime(date) {
      return moment(date).format("hh:mm");
    },
    decryptedError(encryptedData) {
      return getDecryptedError(encryptedData);
    },
    closeNotificationCenter() {
      this.$store.dispatch("notification/hideNotificationCenter");
    },
    expandMoreNotification(id) {
      this.$store.dispatch("notification/expandNotification", {
        id: id,
        expand: true,
      });
    },
    expandLessNotification(id) {
      this.$store.dispatch("notification/expandNotification", {
        id: id,
        expand: false,
      });
    },
    closeNotification(id) {
      this.$store.dispatch("notification/remove", id);
    },
    setNotificationReaded(notification) {
      if (notification.isNotReaded)
        this.$store.dispatch("notification/readed", notification.id);
    },
  },
  created() {},
};
</script>

<style lang="less">
@notificationCenterWidth: 400px;
@rightSpace: 20px;

#notification-center {
  position: fixed;
  top: 88px;
  right: @rightSpace;
  width: @notificationCenterWidth;
  height: ~"calc(100vh - 112px)";
  background-color: #ffffff;
  border-radius: 12px;
  z-index: 960;

  .shadow-level-3();
  .flex-col();

  .title {
    text-transform: uppercase;
    font-size: 0.9rem;
    height: 60px;
    position: relative;
    flex-shrink: 0;
    font-family: @roboto-medium;
    .flex-center();

    .close {
      position: absolute;
      right: 20px;
      user-select: none;
      cursor: pointer;
      border-radius: 50%;
      padding: 3px;

      &.hover:hover,
      &.selected {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .card-container {
    overflow: auto;
    max-height: 100%;
    padding-top: 10px;
    .flex-col-h-center();
    flex-direction: column-reverse;
  }

  .card {
    text-align: left;
    margin-bottom: 20px;
    width: 90%;
    border-radius: 10px;
    transition: transform 150ms ease-in-out;
    transform-origin: top right;
    position: relative;
    .shadow-level-1();

    &.start-remove-animation {
      transform: scale(0);
    }

    .badge {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: @orange;
      position: absolute;
      top: -5px;
      left: -5px;
      z-index: 1600;
      animation: 3s linear 1s infinite shrinkAndExpandAnimation;
    }

    @keyframes shrinkAndExpandAnimation {
      45% {
        transform: scale(1);
        background-color: @orange;
      }
      50% {
        transform: scale(1.5);
        background-color: @orange-lighter;
      }
      55% {
        transform: scale(1);
        background-color: @orange;
      }
    }

    .header {
      color: @white;
      height: 40px;
      border-radius: 10px 10px 0 0;
      padding: 0 0 0 20px;
      text-transform: capitalize;
      background-color: @black;
      position: relative;
      .flex-row-h-left();
      .flex-row-v-center();

      .close {
        position: absolute;
        right: 10px;
        user-select: none;
        cursor: pointer;
        border-radius: 50%;
        padding: 3px;

        &.hover:hover,
        &.selected {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    .body {
      padding: 20px;
      font-size: 0.9rem;
      .flex-col();
    }

    .footer {
      padding: 0 15px 10px 20px;
      .flex-row();

      .copy {
        user-select: none;
        cursor: pointer;
        border-radius: 50%;
        padding: 3px;

        &.hover:hover,
        &.selected {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }

      .expand {
        margin-left: 10px;
        user-select: none;
        cursor: pointer;
        border-radius: 50%;
        padding: 3px;

        &.hover:hover,
        &.selected {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }

      .spacer {
        flex-grow: 1;
      }

      .time {
        font-size: 0.8rem;
        vertical-align: bottom;

        .flex-row-v-bot();
      }
    }

    .expanded-container {
      height: 0;
      overflow: hidden;
      transform: scaleY(0%);
      transform-origin: top;
      transition: transform 150ms ease-out;
      .flex-col();

      &.visible {
        height: auto;
        transform: scaleY(100%);
        padding: 20px;
      }

      .subtitle {
        font-size: 0.9rem;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .encrypted-error-container {
        border: 1px solid @grey;
        color: @grey;
        max-height: 200px;
        overflow: auto;
        word-break: break-all;
        font-size: 0.8rem;
        padding: 5px;
        margin-bottom: 10px;
      }
    }

    &.error {
      .header {
        background-color: @error-red;
      }
    }
  }
}

.no-notification {
  font-size: 1.1rem;
  flex-grow: 1;
  position: relative;
  color: @grey;
  padding-bottom: 160px;
  .flex-center();
  .flex-col();

  .sun {
    margin-top: 24px;
  }
}

// ANIMATION

#notification-center {
  &.slide-enter-from,
  &.slide-leave-to {
    transform: translateX(50px);
    opacity: 0;
  }

  &.slide-enter-to,
  &.slide-leave-from {
    transform: translateX(0px);
    opacity: 1;
  }

  &.slide-enter-active,
  &.slide-leave-active {
    transition: transform 350ms ease, opacity 350ms ease;
  }
}
</style>

<template>
  <div class="design-center" :class="{ show: designCenterIsVisible }">
    <!-- TITLE -->

    <div class="title">Champs de l'écran</div>

    <!-- FIELD -->

    <div
      draggable="true"
      class="field"
      v-for="(field, index) in inactiveDesignFields"
      :key="index"
      @dragstart="startDrag($event, field)"
    >
      <GoogleSymbol
        :symbol="getIconSymbol(field)"
        size="24px"
        :hover="false"
        class="icon"
      />

      <span>{{ field.label }}</span>
    </div>

    <span class="text" v-if="inactiveDesignFields.length === 0">
      Aucun champs disponible
    </span>

    <!-- TITLE -->

    <!-- <div class="title">Champs personnalisé</div> -->

    <!-- ADD CUSTOM FIELD BUTTON -->

    <!-- <Button
      :label="'Champs personnalisé'"
      :symbol="'add'"
      class="customFieldButton"
      @click="showDialog"
    /> -->
  </div>

  <FloatingContainer
    v-if="designCenterIsVisible"
    :bottom="'24px'"
    :right="'0px'"
    class="floating-container"
    :isDesignButtons="true"
  >
    <FloatingButton
      :label="'Annuler'"
      :type="'cancel'"
      :symbol="'close'"
      @click="cancelDesign"
    />

    <FloatingButton
      v-if="showSaveButton"
      class="validate-button"
      :label="'Sauvegarder'"
      :type="'validate'"
      :symbol="'save'"
      @click="saveDesign"
    />
  </FloatingContainer>
</template>

<script>
import FloatingContainer from "@/components/FloatingContainer";
import FloatingButton from "@/components/basics/FloatingButton";
import GoogleSymbol from "./GoogleSymbol.vue";
// import Button from "@/components/basics/Button";

export default {
  name: "design-center",
  components: {
    FloatingContainer,
    FloatingButton,
    GoogleSymbol,
    // Button,
  },
  data() {
    return {
      displayDialog: false,
    };
  },
  computed: {
    designCenterIsVisible() {
      return this.$store.state.design.designCenterIsVisible;
    },
    designUpdated() {
      return this.$store.state.design.designUpdated;
    },
    designFields() {
      if (this.$store.state.design.designFields)
        return this.$store.state.design.designFields;
      return null;
    },
    inactiveDesignFields() {
      if (this.designFields) {
        return this.designFields.filter((el) => !el.active && el.name != "id");
      }
      return null;
    },
    showSaveButton() {
      return (
        this.designCenterIsVisible === true &&
        this.$store.getters["design/designStructureHasChanged"] === true
      );
    },
  },
  methods: {
    startDrag(evt, field) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("fieldId", field.id);
    },
    confirmLeave() {
      return window.confirm(
        "Les changements apporté au design non pas été sauvegardé, souhaitez vous poursuivre sans sauvegarder ?"
      );
    },

    beforeWindowUnload(e) {
      if (!this.confirmLeave()) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
    saveDesign() {
      this.$store.dispatch("design/saveDesign");
    },
    cancelDesign() {
      if (this.$store.getters["design/designStructureHasChanged"]) {
        this.$store.dispatch("dialog/showUnsavedDesignDataDialog");
        this.$store.dispatch("dialog/setButtons", [
          {
            label: "Annuler",
            value: false,
          },
          {
            label: "Continuer sans sauvegarder",
            value: true,
            callback: () => {
              this.$store.dispatch("design/resetOriginalDesignStructure");
              this.$store.dispatch("design/hideDesignCenter");
            },
          },
        ]);
      } else {
        this.$store.dispatch("design/hideDesignCenter");
      }
    },
    getIconSymbol(field) {
      if (field.type === "STRING") {
        return "text_fields";
      } else if (
        field.type === "TABLE" ||
        field.type === "SUBTABLE" ||
        field.type === "SUBTABLE-ADD"
      ) {
        return "table";
      } else if (field.type === "LIST") {
        return "list";
      }

      return "menu";
    },
    showDialog() {
      this.$store.dispatch("dialog/setComponentName", "CustomFieldDialog");
    },
  },
  watch: {
    designCenterIsVisible: function (newValue) {
      if (newValue === false) {
        window.removeEventListener("beforeunload", this.beforeWindowUnload);
      }
    },
    designUpdated: function (newValue) {
      if (newValue === true) {
        window.addEventListener("beforeunload", this.beforeWindowUnload);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.design-center {
  height: ~"calc(100vh - 112px)";
  width: @designcenter-width;
  position: fixed;
  right: 24px;
  top: 88px;
  background: @white;
  transform: translateX(calc(@designcenter-width + 24px));
  transition: transform 350ms ease, opacity 350ms ease;
  z-index: 750;
  // padding: 64px 0;
  border-radius: 12px;
  opacity: 0;

  .flex-col();
  .shadow-level-2();

  &.show {
    transform: translateX(0);
    opacity: 1;
  }

  .title {
    .label-extra-large-font();
    margin: 24px 0 0 24px;
  }

  .field {
    border-radius: 8px;
    padding: 12px 16px 12px 16px;
    margin: 24px 24px 0 24px;
    user-select: none;
    border: 1px solid #79747e;
    background: #fffbfe;
    cursor: grab;
    .label-large-font();
    .flex-row-v-center();

    .icon {
      margin-right: 8px;
    }
  }

  .customFieldButton {
    align-self: flex-start;
    margin: 24px 0 0 24px;
  }

  .text {
    margin: 24px 0 0 24px;
    padding: 0 24px 0 0;
    .body-medium-font();
  }
}

.floating-container {
  right: calc(@designcenter-width + 48px) !important;

  .validate-button {
    margin-left: 16px;
  }
}
</style>

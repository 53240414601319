<template>
  <SideNav />
  <div id="global-container" :class="{ sidenavIsVisible }">
    <TopBar />
    <div id="content" :class="{ shrink: designCenterIsVisible }">
      <router-view v-slot="{ Component }">
        <transition name="fade" appear :key="$route.fullPath">
          <div class="transition-hover">
            <component :is="Component" />
          </div>
        </transition>
      </router-view>
    </div>
  </div>
  <NotificationCenter />
  <DesignCenter />
  <Tooltip />
  <Dialog />
  <GlobalDialog />
  <GlobalContextualMenu />
  <ToasterContainer />
</template>

<script>
import NotificationCenter from "@/components/NotificationCenter.vue";
import SideNav from "@/components/SideNav.vue";
import TopBar from "@/components/TopBar.vue";
import DesignCenter from "@/components/DesignCenter.vue";
import ToasterContainer from "@/components/ToasterContainer.vue";
import Tooltip from "@/components/Tooltip.vue";
import Dialog from "@/components/Dialog";
import GlobalDialog from "@/components/GlobalDialog";
import GlobalContextualMenu from "@/components/GlobalContextualMenu";

export default {
  components: {
    NotificationCenter,
    SideNav,
    TopBar,
    DesignCenter,
    ToasterContainer,
    Tooltip,
    Dialog,
    GlobalDialog,
    GlobalContextualMenu,
  },
  name: "default-layout",
  computed: {
    designCenterIsVisible() {
      return this.$store.state.design.designCenterIsVisible;
    },
    sidenavIsVisible() {
      return this.$store.state.sidenav.sidenavIsVisible;
    },  
  },
};
</script>

<style lang="less">
#global-container {
  width: 100%;
  padding-top: @topbar-height;
  z-index: 0;
  .flex-col();

  #content {
    width: 100%;
    padding: 24px;
    padding-bottom: 104px;

    transition: all 300ms ease;

    &.shrink {
      padding-right: @designcenter-width + 48px;
    }
  }
}

// ANIMATION

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms ease, transform 300ms ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

#global-container {
  padding-left: 65px;
  transition: padding-left 250ms ease;
  
  &.sidenavIsVisible {
    padding-left: @sidenav-width;
  }
}
</style>

<template>
  <transition name="fade">
    <component v-bind:is="componentName" class="global-dialog"></component>
  </transition>
</template>

<script>
import CustomFieldDialog from "@/components/design_center/CustomFieldDialog";

export default {
  name: "global-dialog",
  components: { CustomFieldDialog },
  computed: {
    componentName() {
      return this.$store.state.dialog.componentName;
    },
  },
};
</script>

<style lang="less" scoped>
.global-dialog {
  &.fade-enter-from,
  &.fade-leave-to {
    opacity: 0;
  }

  &.fade-enter-to,
  &.fade-leave {
    opacity: 1;
  }

  &.fade-enter-active,
  &.fade-leave-active {
    transition: opacity 350ms ease;
  }
}
</style>
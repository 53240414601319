const getDefaultState = () => {
  return {
    componentName: null,
    x: null,
    y: null,
  };
};

const state = getDefaultState();

const actions = {
  setComponentName({ commit }, componentName) {
    commit("setComponentName", componentName);
  },
  setCoord({ commit }, coord) {
    commit("setCoord", coord);
  },
};

const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },
  setComponentName(state, componentName) {
    state.componentName = componentName;
  },
  setCoord(state, coord) {
    state.x = coord.x;
    state.y = coord.y;
  },
};

const getters = {};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};

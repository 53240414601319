<template>
  <div class="select-container">
    <input
      class="select"
      type="text"
      name=""
      :id="id"
      @focus="onFocus"
      @blur="onBlur"
      :value="textValue"
      @input="updateValue"
      ref="input"
    />
    <label class="label" :class="{ selectIsFocused }" :for="id">{{
      label
    }}</label>

    <GoogleSymbol
      symbol="expand_more"
      @click="toggleFocus"
      class="expand-more"
      :class="{ selected: showOptions }"
    />

    <div class="options" v-if="showOptions">
      <div
        class="option"
        :data-value="null"
        :data-label="''"
        @click="selectOption"
      >
        Selectionner
      </div>
      <div
        class="option"
        v-for="(option, key) in options"
        :key="key"
        :data-value="option.value"
        :data-label="option.label"
        @click="selectOption"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
import GoogleSymbol from "@/components/GoogleSymbol";

export default {
  name: "select-component",
  components: { GoogleSymbol },
  data() {
    return {
      selectIsFocused: false,
      showOptions: false,
      textValue: this.getLabelByValue(this.modelValue),
    };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  methods: {
    onFocus() {
      if (!this.selectIsFocused) this.selectIsFocused = true;

      this.showOptions = true;
    },
    onBlur() {
      if (
        ((this.modelValue && this.modelValue.length == 0) ||
          !this.modelValue) &&
        this.selectIsFocused
      ) {
        this.selectIsFocused = false;
      }
      else this.selectIsFocused = true;

      setTimeout(() => {
        this.showOptions = false;
      }, 300);
    },
    updateFocus() {
      if (this.modelValue == null || this.modelValue.length == 0) {
        this.selectIsFocused = false;
      } else {
        this.selectIsFocused = true;
      }
    },
    updateValue() {
      // this.$emit("update:modelValue", event.target.value);
    },
    selectOption(event) {
      this.$emit("update:modelValue", event.target.getAttribute("data-value"));
      this.textValue = event.target.getAttribute("data-label");
      this.showOptions = false;
    },
    getLabelByValue(value) {
      const elem = this.options.find((el) => el.value == value);

      if (elem) return elem.label;

      return "";
    },
    toggleFocus() {
      if (!this.showOptions) this.$refs.input.focus();
      else this.$refs.input.blur();
    },
  },
  watch: {
    modelValue: function (newValue) {
      this.textValue = this.getLabelByValue(newValue);
      this.updateFocus();
    },
  },
  created() {
    this.updateFocus();
  },
};
</script>

<style lang="less" scoped>
.select-container {
  position: relative;

  .select {
    width: 100%;
    padding: 16px;
    height: 56px;
    color: @title-font-black;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    .flex-row-v-center();
    .body-medium-font();
  }

  .label {
    position: absolute;
    left: 11px;
    top: -10px;
    background-color: @white;
    padding: 0 5px;
    transform: translateY(28px) scale(1);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    display: inline-block;
    .label-large-font();
    color: @input-label-font-black;

    &.selectIsFocused {
      transform: translateY(0) scale(0.9);
    }
  }

  .expand-more {
    position: absolute;
    right: 16px;
    top: 16px;
    transform: rotate(0deg);
    transition: transform 250ms ease;

    &.selected {
      transform: rotate(180deg);
    }
  }

  .options {
    position: absolute;
    top: 56px;
    padding: 8px 0px;
    width: 100%;
    background-color: #eff3f9;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    z-index: 1000;

    .option {
      height: 32px;
      padding: 0 16px;
      color: @title-font-black;
      cursor: pointer;
      .flex-row-v-center();
      .body-small-font();

      &:hover {
        background-color: rgba(0, 0, 0, 0.15);
      }
    }
  }
}
</style>